import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, GET_DRAW, CREATE_DRAW } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";
import { onSuccess } from "./success";

function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getDraws() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/listGenerateBoulpik")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DRAW, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function createDraw(date, price, initial, hour) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/createTirage", {
      end: date,
      price,
      initial,
      hour,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(CREATE_DRAW, response.data.data));
          dispatch(apiEnd());
          dispatch(onSuccess("Create_Draw", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}
