import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import Cookie from "react-cookies";
import { connect } from "react-redux";
import { Button, Icon, Checkbox, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { login } from "../../redux/actions/user";
import { endNavigate, navigate } from "../../redux/actions/navigation";

//Components
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Input from "../../components/Input/Input";

import styles from "./LoginScreen.module.css";

class LoginScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: "",
			rememberMe: false,
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		const cookie = await Cookie.load("userPreferences");
		if (cookie !== undefined) {
			this.setState({
				email: cookie.email,
				rememberMe: cookie.rememberMe,
			});
		}
	};

	handleSignIn = async () => {
		const { email, password, rememberMe } = this.state;
		await this.props.dispatch(login(email, password, rememberMe));
	};

	handleSignUp = (e) => {
		e.preventDefault();
		this.props.dispatch(navigate("/register"));
	};

	handleForgotPassword = (e) => {
		e.preventDefault();
		this.props.dispatch(navigate("/forgot-password"));
	};

	handleInputOnChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleCheckOnChange = () => {
		this.setState({ rememberMe: !this.state.rememberMe });
	};

	render() {
		const { email, password, rememberMe } = this.state;
		const { navigation } = this.props;

		if (navigation.navigate && window.location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<div className={styles.container}>
				<div className={styles.logoContainer}>
					<Image src={"/logo.png"} className={styles.logo} />
				</div>

				<div className={styles.signUpText} onClick={this.handleSignUp}>
					<div className={styles.text}>{getLabel("DontHaveAnAccount")}</div>
					<div className={styles.hyperlink}>{getLabel("SignUp")}</div>
				</div>

				<div className={styles.inputsContainer}>
					<Input
						icon={"phone"}
						name={"email"}
						value={email}
						placeholder={getLabel("EmailOrPhone")}
						onChange={this.handleInputOnChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("Password")}
						onChange={this.handleInputOnChange}
					/>
					<div className={styles.actionsContainer}>
						<Checkbox
							label={getLabel("RememberMe")}
							onChange={this.handleCheckOnChange}
							checked={rememberMe}
							className={styles.text}
						/>
						<div className={styles.text} onClick={this.handleForgotPassword}>
							{getLabel("ForgotYourPassword")}
						</div>
					</div>
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleSignIn}>
						<Icon name={"sign-in"} />
						<div className={styles.text}>{getLabel("SignIn")}</div>
					</Button>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ navigation }) {
	return { navigation };
}

export default connect(mapStateToProps)(LoginScreen);
