import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import Draws from "../../components/Draws/Draws";
import { fetchUserInfo } from "../../redux/actions/user";
import { endNavigate, navigate } from "../../redux/actions/navigation";

import styles from "./BoulpikScreen.module.css";

class BoulpikScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(fetchUserInfo(this.props.user._id));
	};

	handleAddBoulpik = () => {
		this.props.dispatch(navigate("/my-boulpik/choose-draw"));
	};

	render() {
		const { boulpik, navigation, location } = this.props;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<Container title={getLabel("MyBoulpik")}>
				<Button
					color={"red"}
					icon={"plus"}
					content={getLabel("Buy") + " Boulpik"}
					className={styles.button}
					onClick={this.handleAddBoulpik}
				/>
				<Draws data={boulpik.boulpiks} type={"boulpik"} />
			</Container>
		);
	}
}

function mapStateToProps({ user, boulpik, navigation }) {
	return { user, boulpik, navigation };
}

export default withRouter(connect(mapStateToProps)(BoulpikScreen));
