import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import {
	LOGIN,
	LOGGED_USER,
	API_FAILURE,
	REGISTER_CLIENT,
	LOGOUT,
	GET_BOULPIKS,
	REFRESH_CREDIT,
	EDIT_USER,
	VERIFY_USER,
	VERIFY_PIN,
	RESET_PASSWORD,
	DISMISS_MODAL,
} from "./types";
import Cookie from "react-cookies";
import { getCurrentLanguage } from "../../constants/Utils";
import { navigate } from "./navigation";
import { onSuccess } from "./success";

function requestUserSuccess(type, user, token) {
	return { type, payload: { user, token } };
}

function requestSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function rehydrate() {
	return async (dispatch) => {
		const cookie = await Cookie.load("authToken");
		if (cookie !== undefined) {
			const { user, token } = cookie;
			Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
			Api.defaults.headers.common["userId"] = user._id;
			Cookie.save("authToken", cookie, { path: "/", maxAge: 1800 });
			dispatch(requestUserSuccess(LOGGED_USER, user, token));
		} else {
			const noUser = { _id: null, credit: 0, nom: "", role: "", tel: "", email: "" };
			dispatch(requestUserSuccess(LOGGED_USER, noUser, null));
		}
	};
}

export function login(auth, password, rememberMe) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/authenticate", {
			email: auth,
			motDePasse: password,
		})
			.then((response) => {
				if (response.data.success) {
					const { user, token } = response.data.data;
					const authToken = { user: user, token: token };
					Cookie.save("authToken", authToken, { path: "/", maxAge: 1800 });
					if (rememberMe) {
						const preferences = { email: auth, rememberMe };
						Cookie.save("userPreferences", preferences, { path: "/" });
					} else {
						Cookie.remove("userPreferences");
					}
					Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
					Api.defaults.headers.common["userId"] = user._id;
					dispatch(requestUserSuccess(LOGIN, user, token));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function logout() {
	return (dispatch) => {
		Cookie.remove("authToken");
		Api.defaults.headers.common["Authorization"] = `Bearer `;
		Api.defaults.headers.common["userId"] = "";
		dispatch(requestUserSuccess(LOGOUT, null, null));
	};
}

export function register(nickname, name, phone, city, password, pin) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/users", {
			surnom: nickname,
			nom: name,
			tel: phone,
			ville: city,
			motDePasse: password,
			pin,
		})
			.then((response) => {
				if (response.data.success) {
					const { user, token } = response.data.data;
					const authToken = { user: user, token: token };
					Cookie.save("authToken", authToken, { path: "/", maxAge: 3600 });
					Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
					Api.defaults.headers.common["userId"] = user._id;
					dispatch(requestUserSuccess(REGISTER_CLIENT, user, token));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function registerSeller(name, phone, city, zone, address, password, pin) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/createVendeur", {
			nom: name,
			tel: phone,
			ville: city,
			zone,
			address,
			motDePasse: password,
			pin,
		})
			.then((response) => {
				if (response.data.success) {
					const { user, token } = response.data.data;
					const authToken = { user: user, token: token };
					Cookie.save("authToken", authToken, { path: "/", maxAge: 3600 });
					Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
					Api.defaults.headers.common["userId"] = user._id;
					dispatch(requestUserSuccess(REGISTER_CLIENT, user, token));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function fetchUserInfo(userID) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.get(`/users/${userID}`)
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(GET_BOULPIKS, response.data.data.boulpik));
					dispatch(requestSuccess(REFRESH_CREDIT, response.data.data.user.credit));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function refreshCredit(credit) {
	return (dispatch) => {
		dispatch(requestSuccess(REFRESH_CREDIT, credit));
	};
}

export function transferCredit(user, credit) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/transactions", {
			email: user,
			balance: credit,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(REFRESH_CREDIT, response.data.data.credit));
					dispatch(apiEnd());
					dispatch(onSuccess("Transfer_Credit", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function createAdmin(name, email, phone, city, password) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/createAdmin", {
			nom: name,
			email,
			tel: phone,
			ville: city,
			motDePasse: password,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(apiEnd());
					dispatch(onSuccess("Create_Account", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function createInvestor(name, email, phone, address, password, percent) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/createInvestor", {
			nom: name,
			email,
			percent,
			tel: phone,
			address,
			motDePasse: password,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(apiEnd());
					dispatch(onSuccess("Create_Account", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function createSeller(name, email, phone, city, zone, password, pin) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/createDetaillant", {
			nom: name,
			email,
			tel: phone,
			ville: city,
			zone,
			motDePasse: password,
			pin,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(apiEnd());
					dispatch(onSuccess("Create_Account", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function createDealer(name, email, phone, city, zone, password, address, matricule) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/createDA", {
			nom: name,
			email,
			tel: phone,
			ville: city,
			zone,
			address,
			numero_matricule: matricule,
			motDePasse: password,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(apiEnd());
					dispatch(onSuccess("Create_Account", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function editUser(user, userID, closeModal) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.patch(`/users/${userID}`, {
			user,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(EDIT_USER, response.data.data));
					dispatch(apiEnd());
					closeModal();
					dispatch(onSuccess("Edit_Account", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function monCashRecharge(credit) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/monCash", {
			montant: credit,
		})
			.then((response) => {
				if (response.data.success) {
					//dispatch(requestSuccess(REFRESH_CREDIT, response.data.data.credit));
					dispatch(apiEnd());
					//dispatch(onSuccess("Transfer_Credit", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function verifyUser(email) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/verifyTelEmail", {
			email,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(VERIFY_USER, null));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function verifyPin(email, pin) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/verifyTelEmailPin ", {
			email,
			pin,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(VERIFY_PIN, null));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function resetPassword(email, password) {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/updatePassword ", {
			email,
			password,
		})
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(RESET_PASSWORD, null));
					dispatch(onSuccess("Reset_Password", language));
					dispatch(navigate("/"));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function dismissModals() {
	return (dispatch) => {
		dispatch(requestSuccess(DISMISS_MODAL, null));
	};
}
