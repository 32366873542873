import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getLabel } from "../../constants/Strings";
import { shareDetail } from "../../constants/Utils";
import { getHistory } from "../../redux/actions/history";
import Container from "../../components/Container/Container";
import { Redirect, withRouter } from "react-router-dom";

import { endNavigate } from "../../redux/actions/navigation";

import styles from "./ShareScreen.module.css";
import { Divider } from "semantic-ui-react";
import { getSharePercent } from "../../redux/actions/share";

class ShareScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getSharePercent());
		await this.props.dispatch(getHistory());
	};

	render() {
		const { user, share, history, navigation, location } = this.props;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}
		return (
			<Container title={getLabel("Share")}>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Benefit")}</div>
					{"[" + share.shares.gainAss + "%]"}
					<div className={styles.percent}>
						{parseInt(share.shares.gainAss) * parseInt(shareDetail(history.history))}
					</div>
				</div>
				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("PercentEntr")}</div>
					{"[" + share.shares.pourcentage + "%]"}
					<div className={styles.percent}>
						{parseInt(share.shares.pourcentage) * parseInt(shareDetail(history.history))}
					</div>
				</div>
				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("AccountOff")}</div>
					{" [" + share.shares.compte + "%]"}
					<div className={styles.percent}>{parseInt(share.shares.compte) * parseInt(shareDetail(history.history))}</div>
				</div>
				<Divider section />

				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Unexpected")}</div>
					{"[" + share.shares.imprevu + "%]"}
					<div className={styles.percent}>
						{parseInt(share.shares.imprevu) * parseInt(shareDetail(history.history))}
					</div>
				</div>

				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Earnings")}</div>
					<div className={styles.percent}>{parseInt(user.percent) * parseInt(shareDetail(history.history))}</div>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, share, history, navigation }) {
	return { share, user, history, navigation };
}

export default withRouter(connect(mapStateToProps)(ShareScreen));
