import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, CREATE_BOULPIK, RESET_BOULPIK, ADD_BOULPIK_TO_CART, REFRESH_CREDIT, AUTO_BOULPIK } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";
import { onSuccess } from "./success";

function requestSuccess(type, date, price, boulpik) {
	return { type, payload: { date, price, boulpik } };
}

function requestCartSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function generateBoulpik(date) {
	return dispatch => {
		dispatch(apiStart());
		Api.post("/autoBoulpik", {
			fecha: date
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(AUTO_BOULPIK, null, null, response.data.data));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					const language = getCurrentLanguage();
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				const language = getCurrentLanguage();
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function buyBoulpik(date, price, boulpik) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/GenerateNumberBoulpik", {
			fecha: date,
			price,
			boulpik
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(RESET_BOULPIK, null, null, null));
					dispatch(requestCartSuccess(REFRESH_CREDIT, response.data.data.credit));
					dispatch(apiEnd());
					dispatch(onSuccess(response.data.message, language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function addBoulpikToCart(date, price, boulpik) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/addBoulpikCarrito", {
			fecha: date,
			price,
			boulpik
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestCartSuccess(ADD_BOULPIK_TO_CART, response.data.data));
					dispatch(requestSuccess(RESET_BOULPIK, null, null, null));
					dispatch(apiEnd());
					dispatch(onSuccess("Boulpik_Cart", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function createBoulpik(date, price) {
	return dispatch => {
		dispatch(requestSuccess(CREATE_BOULPIK, date, price, ""));
	};
}
