import { getCurrentUserRole } from "./Utils";
/*
		{
			name: "RechargeCredit",
			route: "/recharge",
			icon: "credit card"
		},
		{
			name: "WithdrawalCredit",
			route: "/withdrawal",
			icon: "credit card"
		}*/

const Menu = {
  User: [
    {
      name: "MyBoulpik",
      route: "/my-boulpik",
      icon: "home",
    },
    {
      name: "RechargeCredit",
      route: "/recharge",
      icon: "credit card",
    },
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "Cart",
      route: "/cart",
      icon: "shopping cart",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "SellersList",
      route: "/authorized-dealers",
      icon: "users",
    },
    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
  ],
  Detaillants: [
    {
      name: "MyBoulpik",
      route: "/my-boulpik",
      icon: "home",
    },
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "Cart",
      route: "/cart",
      icon: "shopping cart",
    },
    {
      name: "Sales",
      route: "/sales",
      icon: "handshake",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
  ],
  Distributeurs: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
  ],
  Admin: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    // {
    // 	name: "CreateSeller",
    // 	route: "/create-seller",
    // 	icon: "add user"
    // },
    {
      name: "CreateDealer",
      route: "/create-dealer",
      icon: "add user",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
  ],
  Super: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "CreateDraw",
      route: "/create-draw",
      icon: "calendar",
    },
    {
      name: "CreateAdmin",
      route: "/create-admin",
      icon: "add user",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
    {
      name: "CreateInvestor",
      route: "/create-investor",
      icon: "add user",
    },
  ],

  Investor: [
    {
      name: "Earnings",
      route: "/benefit",
      icon: "save",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "Share",
      route: "/share",
      icon: "share",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },

    {
      name: "Settings",
      route: "/settings",
      icon: "setting",
    },
    {
      name: "Help",
      route: "/help",
      icon: "help",
    },
  ],
};

const MenuWEB = {
  User: [
    {
      name: "Home",
      route: "/home",
      icon: "home",
    },
    {
      name: "Finance",
      route: "/financial",
      icon: "credit card",
    },
    {
      name: "Cart",
      route: "/cart",
      icon: "shopping cart",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
  ],
  Detaillants: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
  ],
  Distributeurs: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
  ],
  Admin: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "CreateSeller",
      route: "/create-seller",
      icon: "add user",
    },
    {
      name: "CreateDealer",
      route: "/create-dealer",
      icon: "add user",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
  ],
  Super: [
    {
      name: "TransferCredit",
      route: "/transfer-credit",
      icon: "external share",
    },
    {
      name: "MyTransactions",
      route: "/transactions",
      icon: "shuffle",
    },
    {
      name: "WeeklyPrizes",
      route: "/weekly-prizes",
      icon: "trophy",
    },
    {
      name: "NextDraws",
      route: "/draws-schedule",
      icon: "calendar",
    },
    {
      name: "CreateDraw",
      route: "/create-draw",
      icon: "calendar",
    },
    {
      name: "CreateAdmin",
      route: "/create-admin",
      icon: "add user",
    },
    {
      name: "History",
      route: "/history",
      icon: "address book",
    },
    {
      name: "AuthorizedDealers",
      route: "/authorized-dealers",
      icon: "users",
    },
  ],
};

function getMenu(WEB) {
  const role = getCurrentUserRole();

  if (WEB) {
    return MenuWEB[role];
  }
  return Menu[role];
}

export { getMenu };
