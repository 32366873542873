import { GET_DRAW, CREATE_DRAW } from "../actions/types";

export default function draw(state = { draws: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_DRAW:
			return { ...state, draws: payload.data };

		case CREATE_DRAW:
			return { ...state, draws: state.draws.concat(payload.data) };

		default:
			return state;
	}
}
