import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal, Icon, Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { editUser } from "../../redux/actions/user";
import { getCities, getZones } from "../../redux/actions/city";
import { isEmpty } from "lodash";

//Components
import PasswordInput from "../PasswordInput/PasswordInput";
import Input from "../Input/Input";
import DropdownInput from "../DropdownInput/DropdownInput";

import styles from "./UserForm.module.css";

class UserForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			nickname: "",
			name: "",
			city: "",
			zone: "",
			address: "",
			password: "",
			newPassword: ""
		};
	}

	componentDidUpdate = () => {
		if (this.props.visible && isEmpty(this.props.city.cities)) {
			this.props.dispatch(getCities());
		}
	};

	handleEditUser = () => {
		let user = {};
		const { name, nickname, city, password, newPassword } = this.state;
		if (nickname !== "") {
			user = { ...user, surnom: nickname };
		}
		if (name !== "") {
			user = { ...user, nom: name };
		}
		if (city !== "") {
			user = { ...user, ville: city };
		}
		if (password !== "" && newPassword !== "") {
			user = { ...user, motDePasse: password, newMotDePasse: newPassword };
		}
		this.props.dispatch(editUser(user, this.props.user._id, this.props.onClose));
	};

	onInputChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleDropdownChange = async (e, { value, name }) => {
		e.preventDefault();
		if (name === "city") {
			if (value === "" || this.props.user.role === ("User" || "Admin" || "Super")) {
				this.setState({ city: value, zone: "" });
			} else {
				await this.props.dispatch(getZones(value));
				this.setState({ [name]: value, zone: "" });
			}
		} else {
			this.setState({ [name]: value });
		}
	};

	render() {
		const { nickname, name, email, password, newPassword, city, zone, address } = this.state;
		const { visible, onClose, user } = this.props;
		const disabled =
			nickname === "" && name === "" && email === "" && password === "" && newPassword === "" && city === "";
		return (
			<Modal open={visible} onClose={onClose} closeIcon closeOnDimmerClick={false}>
				<Modal.Header>{getLabel("EditAccount")}</Modal.Header>
				<Modal.Content className={styles.content}>
					<Input
						style={{ display: user.role === "User" ? "flex" : "none" }}
						icon={"user secret"}
						name={"nickname"}
						value={nickname}
						placeholder={getLabel("Nickname")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"user"}
						name={"name"}
						value={name}
						placeholder={getLabel("FullName")}
						onChange={this.onInputChange}
					/>
					<DropdownInput
						style={{ display: user.role === ("User" || "Detaillants" || "Distributeurs") ? "flex" : "none" }}
						options={this.props.city.cities}
						icon={"map marker alternate"}
						name={"city"}
						placeholder={getLabel("City")}
						value={city}
						onChange={this.handleDropdownChange}
					/>
					<DropdownInput
						style={{ display: user.role === ("Detaillants" || "Distributeurs") ? "flex" : "none" }}
						disabled={city === ""}
						options={this.props.city.zones}
						icon={"map pin"}
						name={"zone"}
						placeholder={getLabel("Sector")}
						value={zone}
						onChange={this.handleDropdownChange}
					/>
					<Input
						style={{ display: user.role === ("Detaillants" || "Distributeurs") ? "flex" : "none" }}
						icon={"map"}
						name={"address"}
						value={address}
						placeholder={getLabel("Address")}
						onChange={this.onInputChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("OldPassword")}
						onChange={this.onInputChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"newPassword"}
						value={newPassword}
						placeholder={getLabel("NewPassword")}
						onChange={this.onInputChange}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button fluid onClick={this.handleEditUser} color={"green"} disabled={disabled}>
						<Icon name={"edit"} />
						{getLabel("Save")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

function mapStateToProps({ user, city }) {
	return { user, city };
}

export default connect(mapStateToProps)(UserForm);
